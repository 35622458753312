import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import Routes from './routes/Routes';

const app = () => (
  <BrowserRouter>
    <Routes/>
  </BrowserRouter>
);

export default app;
