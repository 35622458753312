import React, { useEffect, lazy, Suspense } from 'react';
import { useLocation, Routes, Route } from 'react-router-dom';


const Home = lazy(() => import('../pages/Home'));
const About = lazy(() => import('../pages/About'));
const Contact = lazy(() => import('../pages/Contact'));
const PrivacyPolicy = lazy(() => import('../pages/PrivacyPolicy'));
const TermsAndConditions = lazy(() => import('../pages/TermsAndConditions'));
const Thecareeros = lazy(() => import('../pages/Works/Thecareeros'));
const Salespacker = lazy(() => import('../pages/Works/Salespacker'));
const Ourpetpolicy = lazy(() => import('../pages/Works/Ourpetpolicy'));
const Flipride = lazy(() => import('../pages/Works/Flipride'));
const Scalefront = lazy(() => import('../pages/Works/Scalefront'));

const AppRoutes = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    document.body.style.overflow = 'auto';
    document.body.style.touchAction = 'auto';

    return () => {
      window.lastLocation = pathname;
    };
  }, [pathname]);

  return (
    <Suspense fallback={<></>}>
      <Routes>
        <Route index element={<Home />} />
        <Route path='about' element={<About />} />
        <Route path='contact' element={<Contact />} />
        <Route path='privacypolicy' element={<PrivacyPolicy />} />
        <Route path='termsandconditions' element={<TermsAndConditions />} />
        <Route path='thecareeros' element={<Thecareeros />} />
        <Route path='salespacker' element={<Salespacker />} />
        <Route path='ourpetpolicy' element={<Ourpetpolicy />} />
        <Route path='flipride' element={<Flipride />} />
        <Route path='scalefront' element={<Scalefront />} />
      </Routes>
    </Suspense>
  );
};

export default React.memo(AppRoutes);
